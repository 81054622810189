/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BadRequest {
  violations: {
    /** @example "object[0].name" */
    path: string;
    /** @example "Expected string, int given" */
    message: string;
  }[];
}

export interface PlaceDictionaryValue {
  id: number;
  name: string;
}

export interface Place {
  /**
   * Primary key
   * @format int64
   */
  id: number;
  /** Title of the place */
  title: string;
  /** City where the place is located */
  city: string;
  /** Address of the place */
  address?: string | null;
  /**
   * Rating of the place
   * @format double
   */
  rating?: number | null;
  /** Short description of the place */
  shortDescription?: string | null;
  /** Working hours in JSON format */
  workingHours?: string[] | null;
  /** Contact phone number */
  phone?: string | null;
  /**
   * ID of the logo
   * @format int64
   */
  logoId?: number | null;
  /**
   * Longitude of the place
   * @format double
   */
  lon?: number | null;
  /**
   * Latitude of the place
   * @format double
   */
  lat?: number | null;
  /** External ID */
  externalId?: string | null;
  /** URL of the logo */
  logoUrl?: string | null;
  /** Link to the place */
  link?: string | null;
  /**
   * Starting price
   * @format int32
   * @default 0
   */
  priceFrom: number;
  /**
   * Ending price
   * @format int32
   * @default 0
   */
  priceTo: number;
  /**
   * Creation timestamp
   * @format date-time
   */
  createdAt?: string | null;
  /**
   * Update timestamp
   * @format date-time
   */
  updatedAt?: string | null;
  types: PlaceDictionaryValue[];
  cuisines: PlaceDictionaryValue[];
  images: PlaceImage[];
  draftId?: number;
}

export interface PlaceDraftPatch {
  /** Title of the place */
  title: string;
  /** City where the place is located */
  city: string;
  /** Address of the place */
  address?: string | null;
  /**
   * Rating of the place
   * @format double
   */
  rating?: number | null;
  /** Short description of the place */
  shortDescription?: string | null;
  /** Working hours in JSON format */
  workingHours?: string[] | null;
  /** Contact phone number */
  phone?: string | null;
  /**
   * ID of the logo
   * @format int64
   */
  logoId?: number | null;
  /**
   * Longitude of the place
   * @format double
   */
  lon?: number | null;
  /**
   * Latitude of the place
   * @format double
   */
  lat?: number | null;
  /** External ID */
  externalId?: string | null;
  /** Link to the place */
  link?: string | null;
  /**
   * Starting price
   * @format int32
   * @default 0
   */
  priceFrom: number;
  /**
   * Ending price
   * @format int32
   * @default 0
   */
  priceTo: number;
  /**
   * Creation timestamp
   * @format date-time
   */
  createdAt?: string | null;
  /**
   * Update timestamp
   * @format date-time
   */
  updatedAt?: string | null;
  types: number[];
  cuisines: number[];
}

export interface PlaceDraft {
  /**
   * Primary key
   * @format int64
   */
  id: number;
  place: Place;
}

export interface PlaceImage {
  id: number;
  path: string;
  url: string;
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://localhost:4080/" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { "Content-Type": type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Places Admin API
 * @version 0.0.1
 * @baseUrl http://localhost:4080/
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  placeDictionary = {
    /**
 * @description Get place dictionary
 *
 * @tags Place Dictionary
 * @name PlaceDictionaryDetail
 * @summary Get place dictionary
 * @request GET:/place-dictionary/{key}
 * @response `200` `{
    total: number,
    items: (PlaceDictionaryValue)[],

}` Done
 * @response `400` `BadRequest` Bad Request
 * @response `401` `void` Unauthorized
 */
    placeDictionaryDetail: (key: "type" | "cuisine", params: RequestParams = {}) =>
      this.request<
        {
          total: number;
          items: PlaceDictionaryValue[];
        },
        BadRequest | void
      >({
        path: `/place-dictionary/${key}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  place = {
    /**
 * @description Get places
 *
 * @tags Place
 * @name PlaceList
 * @summary Get places
 * @request GET:/place
 * @response `200` `{
    total: number,
    items: (Place)[],

}` Done
 * @response `400` `BadRequest` Bad Request
 * @response `401` `void` Unauthorized
 */
    placeList: (
      query: {
        limit: number;
        offset: number;
        q?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          total: number;
          items: Place[];
        },
        BadRequest | void
      >({
        path: `/place`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get place
     *
     * @tags Place
     * @name PlaceDetail
     * @summary Get place
     * @request GET:/place/{placeId}
     * @response `200` `Place` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    placeDetail: (placeId: number, params: RequestParams = {}) =>
      this.request<Place, BadRequest | void>({
        path: `/place/${placeId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Delete place
     *
     * @tags Place
     * @name PlaceDelete
     * @summary Delete place
     * @request DELETE:/place/{placeId}
     * @response `204` `void` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     * @response `404` `BadRequest` Not Found
     */
    placeDelete: (placeId: number, params: RequestParams = {}) =>
      this.request<void, BadRequest | void>({
        path: `/place/${placeId}`,
        method: "DELETE",
        ...params,
      }),
  };
  placeDraft = {
    /**
     * @description Create place draft
     *
     * @tags Place Draft
     * @name PlaceDraftCreate
     * @summary Create place draft
     * @request POST:/place-draft
     * @response `201` `PlaceDraft` Created
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    placeDraftCreate: (
      data: {
        placeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlaceDraft, BadRequest | void>({
        path: `/place-draft`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * @description Get place drafts
 *
 * @tags Place Draft
 * @name PlaceDraftList
 * @summary Get place drafts
 * @request GET:/place-draft
 * @response `200` `{
    total: number,
    items: (PlaceDraft)[],

}` Done
 * @response `400` `BadRequest` Bad Request
 * @response `401` `void` Unauthorized
 */
    placeDraftList: (
      query: {
        limit: number;
        offset: number;
        q?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          total: number;
          items: PlaceDraft[];
        },
        BadRequest | void
      >({
        path: `/place-draft`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get place draft
     *
     * @tags Place Draft
     * @name PlaceDraftDetail
     * @summary Get place draft
     * @request GET:/place-draft/{draftId}
     * @response `200` `PlaceDraft` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    placeDraftDetail: (draftId: number, params: RequestParams = {}) =>
      this.request<PlaceDraft, BadRequest | void>({
        path: `/place-draft/${draftId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update place draft
     *
     * @tags Place Draft
     * @name PlaceDraftPartialUpdate
     * @summary Update place draft
     * @request PATCH:/place-draft/{draftId}
     * @response `200` `PlaceDraft` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    placeDraftPartialUpdate: (draftId: number, data: PlaceDraftPatch, params: RequestParams = {}) =>
      this.request<PlaceDraft, BadRequest | void>({
        path: `/place-draft/${draftId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete place draft
     *
     * @tags Place Draft
     * @name PlaceDraftDelete
     * @summary Delete place draft
     * @request DELETE:/place-draft/{draftId}
     * @response `204` `void` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    placeDraftDelete: (draftId: number, params: RequestParams = {}) =>
      this.request<void, BadRequest | void>({
        path: `/place-draft/${draftId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * @description Publish place draft
     *
     * @tags Place Draft
     * @name PublishCreate
     * @summary Publish place draft
     * @request POST:/place-draft/{draftId}/publish
     * @response `204` `void` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    publishCreate: (draftId: number, data: object, params: RequestParams = {}) =>
      this.request<void, BadRequest | void>({
        path: `/place-draft/${draftId}/publish`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
 * @description Set up place logo
 *
 * @tags Place Draft Logo
 * @name LogoUpdate
 * @summary Set up place logo
 * @request PUT:/place-draft/{draftId}/logo
 * @response `200` `{
    url: string,

}` Done
 * @response `400` `BadRequest` Bad Request
 * @response `401` `void` Unauthorized
 */
    logoUpdate: (
      draftId: number,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          url: string;
        },
        BadRequest | void
      >({
        path: `/place-draft/${draftId}/logo`,
        method: "PUT",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete place draft logo
     *
     * @tags Place Draft Logo
     * @name LogoDelete
     * @summary Delete place draft logo
     * @request DELETE:/place-draft/{draftId}/logo
     * @response `204` `void` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    logoDelete: (draftId: number, params: RequestParams = {}) =>
      this.request<void, BadRequest | void>({
        path: `/place-draft/${draftId}/logo`,
        method: "DELETE",
        ...params,
      }),

    /**
     * @description Upload place image
     *
     * @tags Place Draft Image
     * @name ImageCreate
     * @summary Upload place image
     * @request POST:/place-draft/{draftId}/image
     * @response `200` `PlaceImage` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    imageCreate: (
      draftId: number,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlaceImage, BadRequest | void>({
        path: `/place-draft/${draftId}/image`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete place draft image
     *
     * @tags Place Draft Image
     * @name ImageDelete
     * @summary Delete place draft image
     * @request DELETE:/place-draft/{draftId}/image/{imageId}
     * @response `204` `void` Done
     * @response `400` `BadRequest` Bad Request
     * @response `401` `void` Unauthorized
     */
    imageDelete: (draftId: number, imageId: number, params: RequestParams = {}) =>
      this.request<void, BadRequest | void>({
        path: `/place-draft/${draftId}/image/${imageId}`,
        method: "DELETE",
        ...params,
      }),
  };
}
